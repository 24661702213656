import React from 'react'
import Logo from '../../assets/imgs/logo.webp'
import { Button } from '@mui/material'
import './Header.scss'

export const scrollToContent = (id = 'services') => {
  const headerHeight = document.getElementById('header').clientHeight
  const contentTop = document.getElementById(id).getBoundingClientRect().top + window.pageYOffset
  const offset = contentTop - headerHeight
  window.scrollTo({ behavior: 'smooth', top: offset })
}

export const Header = () => {
  return (
    <header id='header' className='header'>
      <div className='header-container'>
        <div className='logo-container'>
          <img alt='Logo' src={Logo} loading='lazy' width='48' className='logo' />
          <div className='wrapper'>
            <h1 className='glitch'>DEVORRIO</h1>
          </div>
        </div>
        <div className='menu'>
          <Button onClick={() => scrollToContent()} className='menu-item menu-services'>
            SERVICES
          </Button>
          <Button onClick={() => scrollToContent('contact')} className='menu-item menu-contact'>
            CONTACT
          </Button>
        </div>
      </div>
    </header>
  )
}
