import React, { Suspense } from 'react'
import Dashboard from '../dashboard/Dashboard'
import './App.css'

const Services = React.lazy(() => import('../services/Services'))
const HowWeWork = React.lazy(() => import('../howWeWork/HowWeWork'))
const Contact = React.lazy(() => import('../contact/Contact'))
const Footer = React.lazy(() => import('../footer/Footer'))

function App() {
  return (
    <div className='App'>
      <Dashboard />
      <Suspense fallback={<div>Loading...</div>}>
        <Services />
        <HowWeWork />
        <Contact />
        <Footer />
      </Suspense>
    </div>
  )
}

export default App
